<template>
  <a-layout style="padding: 0px; height: 100%; overflow: hidden">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>基础配置</a-breadcrumb-item>
            <a-breadcrumb-item>流程管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="Id == ''" class="Enter-top-right">
          <a-input-group style="width: 300px" compact>
            <a-select style="width: 90px" v-model="value" default-value="name">
              <a-select-option value="name"> 流程名称 </a-select-option>
            </a-select>
            <a-input-search
              placeholder="请输入搜索内容"
              style="width: 210px"
              v-model="key"
              @search="onSearch"
            />
          </a-input-group>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <div v-if="!visible">
              <a-table
                :row-selection="rowSelection"
                rowKey="id"
                :pagination="false"
                :columns="column"
                :data-source="processlist"
                :rowClassName="rowClassName"
              >
                <template slot="action" slot-scope="text, record">
                  <a @click="onEdit(record)">编辑&nbsp;&nbsp;</a>
                  <a-popconfirm
                    title="你确定要重新匹配流程么?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="onreset(record)"
                    @cancel="cancel"
                  >
                    <a href="#">重新匹配流程</a>
                  </a-popconfirm>
                </template>
              </a-table>
              <a-pagination
                v-model="params.current"
                :page-size="params.pageSize"
                show-quick-jumper
                :default-current="2"
                :total="total"
                @change="onpagesize"
              />
            </div>
            <div v-else>
              <div class="process">
                <div class="process-title">流程配图</div>
                <div class="process-body">
                  <div>
                    <p style="font-weight: 600; font-size: 17px">开始</p>
                    <div style="padding-left: 50%">
                      <p class="process-diagram">
                        <a-icon
                          class="diagram-add"
                          @click="onFocus(positionid, positionname, 0)"
                          type="plus"
                        />
                        <a-icon class="diagram-grop" type="caret-down" />
                      </p>
                    </div>
                  </div>
                  <div v-for="(item, index) in form.processNodes" :key="item">
                    <div class="diagram-text">
                      <a-icon style="color: red" type="user-delete" />
                      <span>审批人</span>
                      <span
                        class="text-input"
                        placeholder="请选择审批人"
                        @click="onFocus(item.positionId, item.name, [index])"
                      >
                        {{ item.name }}
                      </span>
                      <span
                        @click="ondelete(index)"
                        v-if="form.processNodes.length > 1"
                        ><a-icon type="close"
                      /></span>
                    </div>
                    <div style="padding-left: 50%">
                      <p class="process-diagram">
                        <a-icon
                          @click="onFocus(positionid, positionname, index + 1)"
                          class="diagram-add"
                          type="plus"
                        />
                        <a-icon class="diagram-grop" type="caret-down" />
                      </p>
                    </div>
                  </div>
                  <p style="font-weight: 600; font-size: 17px">结束</p>
                </div>
              </div>
              <a-modal
                :visible="visibles"
                okText="确定"
                cancelText="取消"
                title="编辑"
                @ok="handleOk"
                @cancel="handlecancel"
              >
                <a-form-model-item label="审批人">
                  <a-select
                    v-model="positionId"
                    style="width: 100%"
                    placeholder="Please select"
                    :filter-option="filterOption"
                    @change="onPosition"
                  >
                    <a-select-option
                      v-for="item in positionlist"
                      :key="item.id + 'B' + item.name"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-modal>
              <div class="pro-sub">
                <a-button @click="onClose">取消</a-button>
                <a-button @click="onSubmit" type="primary"> 提交 </a-button>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const column = [
  {
    title: '流程名称',
    dataIndex: 'name',
  },
  {
    title: '流程步骤',
    dataIndex: 'note',
    // scopedSlots: { customRender: "state" },
  },
  {
    title: '状态',
    dataIndex: 'state',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      column,
      title: '',
      visible: false,
      visibles: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },

      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      value: 'name',
      key: '',
      positionid: '',
      positionname: '',
      positionlist: [],
      processlist: [],
      selectedRowKey: '',
      Id: '',
      processs: {
        name: '',
        positionId: '',
      },
      id: '',
      positionId: '',
      form: {
        name: '',
        processNodes: [
          {
            name: '请选择审批人',
            positionId: '',
          },
        ],
        // processId: "",
        // role: "", //角色名
        // userIds: "",
      },
    }
  },
  methods: {
    onSearch(value) {
      this.params = {
        current: 1,
        pageSize: 10,
      }
      this.params[this.value] = {
        value: value,
        op: '%like%',
      }
      if (value == 'state') {
        this.params[this.value].op = '='
      }
      this.key = ''
      this.getprocess()
    },
    ondelete(index) {
      this.form.processNodes.splice(index, 1)
    },
    onPosition(e) {
      //   console.log(e);
      this.processs.name = e.split('B', 2)[1]
      this.processs.positionId = e.split('B', 2)[0]
      //   console.log(this.processs);
    },
    onFocus(positionId, name, value) {
      this.visibles = true
      if (name == '请选择审批人') {
        this.positionId = name
        return
      }
      this.positionId = positionId + 'B' + name
      //   console.log(this.processs.positionId);
      //   console.log(value);
      this.id = value
      this.processs.positionId = positionId
      this.processs.name = name
    },
    handleOk() {
      if (typeof this.id == 'number') {
        this.form.processNodes.splice(this.id, 0, this.processs)
      } else {
        this.form.processNodes.splice(this.id, 1, this.processs)
      }
      setTimeout(() => {
        this.processs = {
          name: '',
          positionId: '',
        }
      }, 1000)
      //   console.log(this.form.processNodes);
      this.visibles = false
    },
    handlecancel() {
      this.visibles = false
      this.id = ''
    },
    onpagesize(e) {
      this.params.current = e
      this.getTalk()
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form.name = ''
      this.form.processNodes = [
        {
          name: '请选择审批人',
          positionId: '',
        },
      ]
      //   console.log(this.form.processNodes);
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      this.putprocess(this.Id, this.form)
    },
    // 编辑
    onEdit(item) {
      this.Id = item.id
      this.visible = true
      this.getprocessId(item.id)
      this.getpositionlist()
    },
    onreset(item) {
      this.getprocessresetId(item.id)
    },
    async getprocessresetId(id) {
      try {
        const res = await http.getprocessresetId(id)
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.$message.success('匹配成功')
        } else {
          this.$message.success(msg)
        }
      } catch (ex) {
        this.$message.success(ex)
      }
    },
    showConfirm(id) {
      let that = this
      this.$confirm({
        title: '检测到流程变更,是否重新绑定?',
        onOk() {
          setTimeout(() => {
            that.getprocessresetId(id)
          }, 100)
        },
        onCancel() {
          setTimeout(() => {
            this.onClose()
          }, 100)
        },
      })
    },
    async putprocess(id, form) {
      try {
        const res = await http.putprocess(id, form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.form.processNodes = [
            {
              name: '请选择审批人',
              positionId: '',
            },
          ]
          this.showConfirm(this.Id)
          this.visible = false
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getprocess() {
      try {
        const res = await http.getprocess(this.params)
        const { success, data } = res.data
        if (success) {
          this.processlist = data.mapping.records
          this.total = data.mapping.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getprocessId(id) {
      try {
        const res = await http.getprocessId(id)
        const { success, data } = res.data
        if (success) {
          if (data.process == null) {
            return
          }
          this.form.name = data.process.name
          this.form.processNodes = data.process.processNodes
          //   console.log(res);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getpositionlist() {
      try {
        const res = await http.getpositionlist()
        const { success, data } = res.data
        if (success) {
          this.positionlist = data.positions
          this.positionname = data.positions[0].name
          this.positionid = data.positions[0].id
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    timer() {
      return setTimeout(() => {
        this.getprocess()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    this.getprocess()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999;*/
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
  overflow: auto;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
  overflow: hidden;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
//箭头
.process-diagram {
  position: relative;
  height: 124px;
  width: 2px;
  margin-bottom: 14px;
  background: rgba(0, 0, 0, 0.2);
  .diagram-add {
    position: absolute;
    top: 45%;
    left: -11px;
    width: 24px;
    height: 24px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }
  .diagram-grop {
    position: absolute;
    font-size: 14px;
    bottom: -10px;
    left: -6px;
  }
}
.diagram-text {
  width: 240px;
  height: 60px;
  border: 1px solid #000;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  .text-input {
    width: 100px;
    height: 19px;
    color: rgba(0, 0, 0, 0.4);
    border: none;
    font-size: 14px;
    padding: 0 5px;
  }
}
.diagram-text > span {
  margin-right: 12px;
}
.process-title {
  height: 50px;
  line-height: 50px;
  border: 1px solid #e8e8e8;
  font-weight: 600;
  padding: 0 30px;
}
.process {
  width: 100%;
  display: flex;
  flex-direction: column;

  .process-body {
    padding: 24px;
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.pro-sub {
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.pro-sub > .ant-btn {
  margin: 0 10px;
  width: 90px;
  height: 35px;
}
</style>
